<template>
    <div id="letter">
        <p class="one">Well, I'll be going then. Gotta say, I'm a bit disappointed. </p>
        <p class="one">And no, I didn't actually call the guards for you. Loser.</p>
    </div>
</template>

<script>
    export default {
        name: "LetterAlert"
    }
</script>

<style scoped>
    #letter {
        justify-content: normal;
    }
    p {
        font-family: monospace;
    }
</style>