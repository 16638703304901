<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">Why can't I sent this back farther?</p>
            <p class="two">There is no one who can carry it beyond.</p>
            <p class="one">But I want to know my origins.</p>
            <p class="two">Let it be this:
                <audio controls>
                    <source src="https://awikhigan.irlh.org/gen/wl/?id=G33au8VsO3nXe5GqXPZ26z0999itz89e" type="audio/wav">
                    Your browser does not support the audio element.
                </audio>
            </p>
            <p class="one">...water?</p>
            <p class="two">the Ocean. and the Ship. Sea Spray and the terrible rattle of metal.</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList')">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter7070537",
        emits: ['showList']
    }
</script>

<style scoped lang="scss">
    audio {
        height: 20px;
    }
    audio::-webkit-media-controls-current-time-display {
        display: none;
    }
    audio::-webkit-media-controls-time-remaining-display {
        display: none;
    }
    audio::-webkit-media-controls-enclosure {
        background-color: #d9d9d9;
        border-radius: 0;
    }

    p {
        margin: 12px 0;
    }
    @font-face {
        font-family: ff-zwo-corr-web-pro,sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: vendetta,serif;
        font-weight: 700;
        font-style: normal;
    }
    .one {
        text-align: left;
        font-family: ff-zwo-corr-web-pro, sans-serif;
    }
    .two {
        text-align: right;
        font-family: vendetta, serif;
        font-weight: 700;
        font-size: 26px;
    }
</style>