<template>
    <div class="container">
        <div id="intro">
            <div class="pageContainer">
                <div class="title">
                    <h2>The Exhibit Of</h2>
                    <h1 class="mainTitle">Anti-Temporal Letters</h1>
                    <p>Collection I</p>
                </div>
                <div class="viewButton">
                    <router-link :to="{ name: 'CuratorLetter' }">
                        <h2>View the Collection</h2>
                    </router-link>
                </div>
                <div class="footer">
                    <h3>Presented by the Time Council and Living Museum</h3>
                </div>
            </div>
        </div>
        <div class="aboutButton" @click="showModal = true">
            <p>?</p>
        </div>
        <transition name="modal">
            <About v-if="showModal" v-on:closeModal="showModal = false"/>
        </transition>
        <audio id="bgWaves" autoplay loop>
            <source src="https://awikhigan.irlh.org/gen/wl/?id=sbYv0PDfeKSpJUh9zVixou9uHdDDsmV6">
        </audio>
        <video autoplay muted loop playsinline id="myVideo">
            <source src="https://awikhigan.irlh.org/gen/wl/?id=kn41VtGldCQcctovZYfESWpCFWHxL2iA" type="video/mp4">
        </video>
    </div>
</template>

<script>
    import About from "@/components/About";

    export default {
        name: 'Home',
        components: {About},
        data() {
            return {
                showModal: false
            }
        },
        mounted() {
            // setTimeout(this.playBGWaves, 1000)
        },
        methods: {
            playBGWaves() {
                const audio = document.getElementById('bgWaves')
                audio.play()
            }
        }
    }
</script>

<style scoped lang="scss">
    h1, h2, h3, p {
        text-align: center;
    }

    a {
        color: black;
    }

    .aboutButton {
        position: fixed;
        top: 20px;
        right: 40px;
        z-index: 5;
        background-color: #ffedc7;
        padding: 12px 22px;
        border-radius: 40px;
        border: 1px solid #343434;
        cursor: pointer;

        p {
            margin: 0;
            font-size: 25px;
        }
    }

    #intro {
        position: fixed;
        height: 100vh;
        width: 100vw;
        z-index: 4;
    }

    .pageContainer {
        justify-content: space-between;
        @media only screen and (max-width: 800px) {
            width: 95%;
        }
    }

    .title {
        padding-top: 20vh;
        @media only screen and (max-width: 800px) {
            padding-top: 10vh;
        }
    }

    .viewButton {
        background-color: #F8F8F8;
        border: 1px solid #2E2E2E;
        filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.25)) drop-shadow(-5px -3px 5px rgba(0, 0, 0, 0.2));
        width: 500px;
        margin: 0 auto;
        @media only screen and (max-width: 800px) {
            width: 300px;
        }
        h2 {
            margin: 16px 0;
        }
    }

    .mainTitle {
        margin-top: 0;
    }

    h2 {
        @media only screen and (max-width: 800px) {
            font-size: 32px;
        }
    }
    h1 {
        @media only screen and (max-width: 800px) {
            font-size: 48px;
        }
    }
    h3 {
        @media only screen and (max-width: 800px) {
            font-size: 24px;
        }
    }
</style>