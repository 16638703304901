<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">Tell me of when we are free</p>
            <p class="two"><span> </span>How do you know you will one day be free?</p>
            <p class="one bigger">Because I know my people.<br /> <span class="we">We </span> will be free.</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList')">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter3502713",
        emits: ['showList']
    }
</script>

<style scoped lang="scss">
    @font-face {
        font-family: adobe-caslon-pro,serif;
        font-weight: 400;
        font-style: normal;
    }
    .one {
        text-align: left;
        font-family: adobe-caslon-pro, serif;
        font-size: 25px;
        width: 50%;
        line-height: 1;
    }
    .two {
        text-align: right;
        font-family: ocr-a-std, monospace;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
    }
    .we {
        font-style: italic;
    }
    .bigger {
        font-size: 29px;
    }
</style>