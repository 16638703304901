<template>
    <div class="container">
        <div id="letterList">
            <div class="pageContainer">
                <div class="letterBox">
                    <div class="boxTop">
                        <div class="topContent">
                            <span v-if="currentLetter && consciousness < 11" class="number">{{ currentLetterNumber }}</span>
                        </div>
                    </div>
                    <div class="boxContent" v-if="showList">
                        <p class="instructions" v-if="consciousness < 4">We welcome you to browse through the following
                            letters: </p>
                        <p class="instructions sm" v-if="consciousness < 3">(You may need to scroll at times)</p>
                        <p v-if="consciousness < 11" title="Archival ID" :class="classes.slowC">Archival ID</p>
                        <div class="letterEntry" v-for="letter in letterFilter" :key="letter.archivalID">
                            <p class="archivalID" :class="classes['Letter' + letter.archivalID]" :data-text="letter.archivalID" :title="letter.archivalID">{{ letter.archivalID }}</p>
                            <div class="mail">
                                <p class="open" :class="classes['openText']" v-if="letter.status === 'open'"
                                   @click="letterSelected(letter.name, letter)">click to open </p>
                                <img :src="mail.closed" alt="Mail Unopened Icon"
                                     class="mailIcon" v-if="letter.status === 'open'">
                                <img :src="mail.open" alt="Mail Opened Icon"
                                     class="mailIcon" v-if="letter.status === 'closed'">
                            </div>
                        </div>
                    </div>
                    <div class="letterContent" v-if="currentLetter">
                        <component :is="currentLetterComponent"
                                   v-on:showList="showList = true; currentLetter = null; this.consciousness += 1;"
                                   v-on:show813="showing.Letter8137652 = true" v-on:show315="showing.Letter3159756 = true"
                                   v-on:destroy541="showing.Letter5413285 = false"
                                   v-on:destroy415="showing.Letter4152969 = false"
                                   v-on:letDestroy="currentLetter = 'LetterDestroy'; this.background.bgVideo = 'GlassCrack5.html'; myVideo.zDex = 25"
                                   v-on:letBurn="currentLetter = 'LetterBurn'; this.background.bgVideo = 'Fire3.html'; myVideo.zDex = 25"
                                   v-on:letAlert="currentLetter = 'LetterAlert'"></component>
                    </div>
                </div>
            </div>
        </div>
        <div class="aboutButton" @click="showModal = true">
            <p class="aboutQ">?</p>
        </div>
        <transition name="modal">
            <About v-if="showModal" v-on:closeModal="showModal = false"/>
        </transition>
        <audio id="Lv3S">
            <source src="https://awikhigan.irlh.org/gen/wl/?id=s0OzV795CCe7ohLf0Xuew7F05bZL4QnX">
        </audio>
        <audio id="Lv4S">
            <source src="https://awikhigan.irlh.org/gen/wl/?id=NQ9x4kEPsSP9kHFy0PDuFXiebu55V5P7">
        </audio>
        <audio id="Lv5S">
            <source src="https://awikhigan.irlh.org/gen/wl/?id=BP56lofwnkoZ289HuahOoKGMTahwllGx">
        </audio>
        <audio id="HelloWav">
            <source src="https://awikhigan.irlh.org/gen/wl/?id=xXaoCaR8W0gZ3pwwEs2JRszIDdD3llNU">
        </audio>
        <iframe :src="background.bgVideo" frameborder="0" id="myVideo"></iframe>
    </div>
</template>

<script>
    import About from "@/components/About";

    import Letter234567 from "@/pages/Letters/Letter234567";
    import Letter2057692 from "@/pages/Letters/Letter2057692";
    import Letter7070537 from "@/pages/Letters/Letter7070537";
    import Letter3502713 from "@/pages/Letters/Letter3502713";
    import Letter3552610 from "@/pages/Letters/Letter3552610";
    import Letter3159756 from "@/pages/Letters/Letter3159756";
    import Letter4152969 from "@/pages/Letters/Letter4152969";
    import Letter5413285 from "@/pages/Letters/Letter5413285";
    import Letter6157835 from "@/pages/Letters/Letter6157835";
    import Letter8137652 from "@/pages/Letters/Letter8137652";
    import Letter8217159 from "@/pages/Letters/Letter8217159";
    import LetterAlert from "@/pages/Letters/LetterAlert";
    import LetterDestroy from "@/pages/Letters/LetterDestroy";
    import LetterBurn from "@/pages/Letters/LetterBurn";

    export default {
        name: "LetterList",
        components: {About},
        data() {
            return {
                showModal: false,
                currentLetter: null,
                consciousness: 1,
                showList: true,
                lettersATL: [],
                letterNames: {
                    Letter234567: Letter234567,
                    Letter2057692: Letter2057692,
                    Letter7070537: Letter7070537,
                    Letter3502713: Letter3502713,
                    Letter3552610: Letter3552610,
                    Letter3159756: Letter3159756,
                    Letter4152969: Letter4152969,
                    Letter5413285: Letter5413285,
                    Letter6157835: Letter6157835,
                    Letter8137652: Letter8137652,
                    Letter8217159: Letter8217159,
                    LetterAlert: LetterAlert,
                    LetterBurn: LetterBurn,
                    LetterDestroy: LetterDestroy
                },
                showing: {
                    Letter234567: false,
                    Letter2057692: true,
                    Letter7070537: true,
                    Letter3502713: true,
                    Letter3552610: true,
                    Letter3159756: false,
                    Letter4152969: true,
                    Letter5413285: true,
                    Letter6157835: true,
                    Letter8137652: false,
                    Letter8217159: true,
                },
                classes: {
                    Letter234567: [],
                    Letter2057692: [],
                    Letter7070537: [],
                    Letter3502713: [],
                    Letter3552610: [],
                    Letter3159756: [],
                    Letter4152969: [],
                    Letter5413285: [],
                    Letter6157835: [],
                    Letter8137652: [],
                    Letter8217159: [],
                    openText: [],
                    slowC: []
                },
                letterListing: {
                    font: 'monospace',
                    fontSize: '20px',
                    fontColor: '#414141',
                },
                letterBox: {
                    bgColor: '#fff',
                    borderColor: '#000'
                },
                background: {
                    bgVideo: 'GlitchLevel1.html'
                },
                topBar: {
                    tbColor: '#8d8d8d'
                },
                myVideo: {
                    zDex: '1'
                },
                mail: {
                    closed: 'https://awikhigan.irlh.org/gen/wl/?id=xl8iWLAYSWYkYT2rSNOstOZmUb7Z7Mg8',
                    open: 'https://awikhigan.irlh.org/gen/wl/?id=YZ9Ws2Tefvw98iCfnqaIG9NLibkijYJ1'
                }
            }
        },
        computed: {
            letterFilter() {
                this.shuffle(this.lettersATL)
                let helpVar = this.showing

                return this.lettersATL.filter(checkShow)

                function checkShow(letter) {
                    return helpVar[letter.name]
                }
            },
            currentLetterComponent() {
                return this.letterNames[this.currentLetter];
            },
            currentLetterNumber() {
                return this.currentLetter.substring(6)
            }
        },
        watch: {
            consciousness: function () {
                if (this.consciousness === 2) { // Level 2, artifacts
                    this.background.bgVideo = 'GlitchLevel2.html';


                } else if (this.consciousness === 3) { // Level 3, color change
                    this.topBar.tbColor = '#46404c'
                    this.letterBox.bgColor = '#eae7ec'
                    this.background.bgVideo = 'GlitchLevel3.html';

                    const audio = document.getElementById('Lv3S')
                    audio.play()

                } else if (this.consciousness === 4) { // Level 4, artifacts, reorder
                    this.background.bgVideo = 'GlitchLevel4.html';
                    this.letterBox.borderColor = '#3e3348'
                    this.classes.Letter3502713.push('glitch')
                    this.classes.slowC.push('slowGlitch')
                    this.shuffle(this.lettersATL)
                    this.classes.Letter3502713.push('glitchSkew')

                    const audio = document.getElementById('Lv4S')
                    audio.play()

                } else if (this.consciousness === 6) { // Level 5, harsh color shift
                    this.background.bgVideo = 'GlitchLevel5.html';
                    this.letterListing.fontColor = '#ececec'
                    this.letterBox.bgColor = '#413b43'
                    this.mail.closed = 'https://awikhigan.irlh.org/gen/wl/?id=KNxoHOOe0ZKGOtGtdLuJNZrCy8ARy9WK'
                    this.mail.open = 'https://awikhigan.irlh.org/gen/wl/?id=o1FeCtpVOxKWsDUEp5yuD8FdDiznYepM'
                    this.classes.openText.push('hoverGlitch')
                    this.classes.Letter3159756.push('glitchSkew')
                    this.classes.Letter6157835.push('glitchSkew2')

                    const audio = document.getElementById('Lv5S')
                    audio.loop = true
                    audio.play()

                } else if (this.consciousness === 8) { // Level 6, warped
                    this.background.bgVideo = 'GlitchLevel6.html';
                    this.letterBox.bgColor = '#1c1532'
                    this.shuffle(this.lettersATL)
                    this.classes.Letter8137652.push('glitchSkew2')
                    this.classes.Letter4152969.push('slowGlitch')


                } else if (this.consciousness === 10) { // Level 7, reorder, electricity
                    this.background.bgVideo = 'GlitchLevel7.html';
                    this.letterBox.bgColor = '#171618'
                    this.shuffle(this.lettersATL)

                } else if (this.consciousness === 11) { // Level 8, darkness
                    const audio = document.getElementById('Lv5S')
                    audio.pause()
                    const audio2 = document.getElementById('HelloWav')
                    audio2.play()
                    this.background.bgVideo = 'GlitchLevel8.html';

                    this.letterBox.bgColor = '#000000'
                    this.topBar.tbColor = '#000000'
                    this.letterBox.borderColor = '#a7a7a7'
                    this.classes.openText.pop()

                    this.showing.Letter234567 = true;
                    this.showing.Letter2057692 = false
                    this.showing.Letter7070537 = false
                    this.showing.Letter3502713 = false
                    this.showing.Letter3552610 = false
                    this.showing.Letter3159756 = false
                    this.showing.Letter4152969 = false
                    this.showing.Letter5413285 = false
                    this.showing.Letter6157835 = false
                    this.showing.Letter8137652 = false
                    this.showing.Letter8217159 = false
                }
            }
        },
        methods: {
            letterSelected(letterName, archivalID) {
                this.currentLetter = letterName;
                archivalID.status = 'closed';
                this.showList = false;
            },
            shuffle(array) {
                let currentIndex = array.length, randomIndex;
                while (currentIndex !== 0) {

                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;

                    [array[currentIndex], array[randomIndex]] = [
                        array[randomIndex], array[currentIndex]];
                }
                return array;
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "ATL-Draft2.json", false);
            request.send(null)
            this.lettersATL = (JSON.parse(request.responseText));

            this.shuffle(this.lettersATL)
        }
    }
</script>

<style scoped lang="scss">
    #myVideo {
        z-index: v-bind('myVideo.zDex');
    }

    .aboutQ {
        color: black;
    }

    p {
        color: v-bind('letterListing.fontColor');
        font-family: v-bind('letterListing.font');
        margin: 0;
    }

    .letterEntry p {
        font-family: v-bind('letterListing.font');
    }

    #letterList {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .letterContent {
        color: v-bind('letterListing.fontColor');
    }

    .pageContainer {
        justify-content: center;
        align-items: center;
    }

    .letterBox {
        background-color: v-bind('letterBox.bgColor');
        width: 60vw;
        max-width: 1400px;
        z-index: 12;
        border: 2px v-bind('letterBox.borderColor') solid;
    }

    .boxTop {
        width: 100%;
        height: 40px;
        background-color: v-bind('topBar.tbColor');
        border-bottom: 2px v-bind('letterBox.borderColor') solid;
        display: flex;
        // justify-content: flex-end;
        align-items: center;

        .topContent {
            padding: 20px 10px;

            span {
                font-size: 20px;
            }
        }
    }

    .boxContent {
        padding: 20px 40px;
        overflow-y: scroll;
        max-height: 60vh;
        min-height: 40vh;
    }

    .instructions {
        margin-bottom: 36px;
    }

    .letterEntry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;
        height: 52px;
        border-bottom: 1px solid black;
    }

    .mail {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            white-space: pre;
        }
    }

    .mailIcon {
        width: 52px;
        object-fit: contain;
    }

    .open {
        font-size: 18px;
        cursor: pointer;

        }
    .hoverGlitch {
        position: relative;

        &:hover {
            &:before, &:after{
                position: absolute;
                content: 'click to open';
                transform: translate(-50%,-50%);
                z-index: -1;
            }

            &:before {
                top: 49.5%;
                left: 49.5%;
                color: rgba(252, 252, 252, 0.3);
                animation: distort1 340ms linear infinite;
            }

            &:after {
                top: 50.5%;
                left: 50.5%;
                color: rgba(210, 96, 241, 0.5);
                animation: distort2 300ms linear infinite;
            }
        }

        @keyframes distort1 {
            0%    { top: 49.5%; left: 49.5%; }
            12.5% { top: 49.5%; left: 70%; }
            37.5% { top: 50%; left: 50.5%; }
            50%   { top: 80.5%; left: 50.5%; }
            62.5% { top: 80.5%; left: 50%; }
            75%   { top: 50.5%; left: 49.5%; }
            87.5% { top: 50%; left: 69.5%; }
            100%  { top: 49.5%; left: 59.5%; }
        }

        @keyframes distort2 {
            0%    { top: 50.5%; left: 50.5%; }
            12.5% { top: 50%; left: 50.5%; }
            25%   { top: 49.5%; left: 30.5%; }
            37.5% { top: 49.5%; left: 30%; }
            50%   { top: 49.5%; left: 79.5%; }
            75%   { top: 60.5%; left: 49.5%; }
            87.5% { top: 60.5%; left: 40%; }
            100%  { top: 60.5%; left: 40.5%; }
        }
    }


    .letterContent {
        overflow-y: scroll;
        max-height: 60vh;
        min-height: 40vh;

    }

    .aboutButton {
        position: fixed;
        top: 20px;
        right: 40px;
        z-index: 5;
        background-color: #ffedc7;
        padding: 12px 22px;
        border-radius: 40px;
        border: 1px solid #343434;
        cursor: pointer;

        p {
            margin: 0;
            font-size: 25px;
        }
    }

    .archivalID {
        letter-spacing: 5px;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 15px;    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px black;
        // background-color: v-bind('letterListing.fontColor');
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: v-bind('topBar.tbColor');
        border-radius: 5px;
        border: 1px solid v-bind('letterListing.fontColor');
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #48444c;
    }

    .slowGlitch {
        animation: glitch 5s linear infinite;
    }

    @keyframes glitch{
        2%,64%{
            transform: translate(2px,0) skew(0deg);
        }
        4%,60%{
            transform: translate(-2px,0) skew(0deg);
        }
        62%{
            transform: translate(0,0) skew(5deg);
        }
    }

    .slowGlitch:before,
    .slowGlitch:after{
        content: attr(title);
        position: absolute;
        left: 0;
    }

    .slowGlitch:before{
        animation: glitchTop 3s linear infinite;
        clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    }

    @keyframes glitchTop{
        2%,64%{
            transform: translate(2px,-2px);
        }
        4%,60%{
            transform: translate(-2px,2px);
        }
        62%{
            transform: translate(13px,-1px) skew(-13deg);
        }
    }

    .slowGlitch:after{
        animation: glitchBottom 2.5s linear infinite;
        clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
        -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    }

    @keyframes glitchBottom{
        2%,64%{
            transform: translate(2px,0);
        }
        4%,60%{
            transform: translate(-2px,0);
        }
        62%{
            transform: translate(-22px,5px) skew(21deg);
        }
    }


    @mixin glitchCopy {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .glitchSkew {
        position: relative;
        //color: white;
        //font-size: 4em;
        animation: glitch-skew 5s infinite linear alternate-reverse;

        // Creates a copy before our text.
        &::before{
            // Duplicates our text with the mixin.
            @include glitchCopy;
            left: 2px;
            text-shadow: -2px 0 #a947fd;
            /* Creates an initial clip for our glitch. This works in
            a typical top,right,bottom,left fashion and creates a mask
            to only show a certain part of the glitch at a time. */
            clip: rect(44px, 450px, 56px, 0);

            animation: glitch-anim 5s infinite linear alternate-reverse;
        }

        &::after {
            @include glitchCopy;
            left: -2px;
            text-shadow: -2px 0 #00fff9, 2px 2px #a947fd;
            animation: glitch-anim2 1s infinite linear alternate-reverse;
        }
    }

    @keyframes glitch-anim {
        $steps: 20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
                transform: skew((random(100) / 100) + deg);
            }
        }
    }

    @keyframes glitch-anim2 {
        $steps: 20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
                transform: skew((random(100) / 100) + deg);
            }
        }
    }

    @keyframes glitch-skew {
        $steps: 10;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                transform: skew((random(10) - 5) + deg);
            }
        }
    }

    .glitchSkew2 {
        position: relative;
        //color: white;
        //font-size: 4em;
        /* Animation provies a slight random skew. Check bottom of doc
        for more information on how to random skew. */
        animation: glitch-skew 1s infinite linear alternate-reverse;

        // Creates a copy before our text.
        &::before{
            // Duplicates our text with the mixin.
            @include glitchCopy;
            left: 2px;
            text-shadow: -2px 0 #a947fd;
            /* Creates an initial clip for our glitch. This works in
            a typical top,right,bottom,left fashion and creates a mask
            to only show a certain part of the glitch at a time. */
            clip: rect(44px, 450px, 56px, 0);
            /* Runs our glitch-anim defined below to run in a 5s loop, infinitely,
            with an alternating animation to keep things fresh. */
            animation: glitch-anim3 5s infinite linear alternate-reverse;
        }

        &::after {
            @include glitchCopy;
            left: -2px;
            text-shadow: -2px 0 #00fff9, 2px 2px #a947fd;
            animation: glitch-anim4 1s infinite linear alternate-reverse;
        }
    }

    @keyframes glitch-anim3 {
        $steps: 5;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
                transform: skew((random(100) / 100) + deg);
            }
        }
    }

    @keyframes glitch-anim4 {
        $steps: 3;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
                transform: skew((random(100) / 100) + deg);
            }
        }
    }

    @keyframes glitch-skew {
        $steps: 10;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                transform: skew((random(10) - 5) + deg);
            }
        }
    }


    .sm {
        font-size: 20px;
    }
</style>