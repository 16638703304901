<template>
    <div class="container">
        <div id="room">
            <transition name="fade" mode="out-in">
                <div class="button" @click="moveCamera(myCamera); playWalk(); clicked = true;" v-if="!clicked">
                    <h2>Approach the Exhibit</h2>
                </div>
            </transition>
        </div>
        <canvas id="bg"></canvas>
        <audio id="walkingAudio">
            <source src="https://awikhigan.irlh.org/gen/wl/?id=gFdQt4NSzjINJlwu8qBO1374gdXfcBX3">
        </audio>
        <audio id="welcomeAudio">
            <source src="https://awikhigan.irlh.org/gen/wl/?id=MzzkL1eiIW9HulFpjBCH2MT5sI650XL8">
        </audio>
    </div>
</template>

<script>
    import * as TWEEN from "@tweenjs/tween.js";
    import * as THREE from "three";

    export default {
        name: "Room",
        data() {
            return {
                myCamera: null,
                clicked: false
            }
        },
        mounted() {
            const scene = new THREE.Scene()
            const loader = new THREE.ObjectLoader()
            const camera = new THREE.PerspectiveCamera(20, window.innerWidth / window.innerHeight, 0.1, 500)
            const renderer = new THREE.WebGLRenderer({
                canvas: document.querySelector('#bg'),
            })

            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(window.innerWidth, window.innerHeight)
            camera.position.set(4.5, .75, 0)
            camera.rotation.set(0, 1.559, 0)

            const buildingMaterial = new THREE.MeshStandardMaterial({
                color: 0xfffffa
            });

            const floorGeometry = new THREE.CylinderGeometry(1, 1, 1, 8, 1, false);
            const floorMesh = new THREE.Mesh(floorGeometry, buildingMaterial);
            floorMesh.scale.set(5, .2, 5)
            floorMesh.position.set(0, 0, 0)
            floorMesh.receiveShadow = true

            const wallGeometry = new THREE.BoxGeometry(1, 1, 1, 1, 1, 1)
            const wallMesh = new THREE.Mesh(wallGeometry, buildingMaterial)
            wallMesh.position.set(-4, 0, 0)
            wallMesh.rotation.set(0, 0, -0.05)
            wallMesh.scale.set(.2, 8, 8)
            wallMesh.receiveShadow = true

            scene.add(floorMesh, wallMesh)

            const ambientLight = new THREE.AmbientLight(0xFFFFFF, 0.2);
            scene.add(ambientLight)

            const pointLight = new THREE.PointLight(0xFFFFFF, 0.7)
            pointLight.castShadow = true
            pointLight.position.set(0, 5, 0)
            scene.add(pointLight)

            const directionalLight = new THREE.DirectionalLight(0xFFFFFF, 0.4)
            directionalLight.castShadow = true
            directionalLight.position.set(9.5, 4.3, 4.5)
            scene.add(directionalLight)

            loader.load(
                "ATLPillar1.json",
                function (obj) {
                    obj.position.set(-1.4, 0, .09)
                    obj.rotation.set(0, 70.01, 0)
                    obj.castShadow = true
                    scene.add(obj);
                },
                function (xhr) {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                },
                function (err) {
                    console.error('An error happened: ' + err);
                }
            );

            function animate(time) {
                requestAnimationFrame(animate)
                TWEEN.update(time)
                renderer.render(scene, camera)
            }

            this.myCamera = camera;
            animate()

            setTimeout(this.playWelcome, 1000)

        },
        methods: {
            playWalk() {
                const audio = document.getElementById('walkingAudio')
                audio.play()
            },
            playWelcome() {
                const WelcomeAudio = document.getElementById('welcomeAudio')
                WelcomeAudio.play()
            },
            moveCamera(camera) {
                const coords = {x: camera.position.x, y: camera.position.y};

                const tween = new TWEEN.Tween(coords)
                    .to({x: -1.50, y: 0.70}, 5000)
                    .easing(TWEEN.Easing.Sinusoidal.Out)
                    .onUpdate(() =>
                        camera.position.set(coords.x, coords.y, camera.position.z)
                    )
                    .start()

                console.log(tween)

                setTimeout(() => {
                    this.$router.push('Intro')

                }, 6000)
            }
        }
    }
</script>

<style scoped lang="scss">
    #room {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    .button {
        background-color: rgba(255, 233, 194, 0.5);
        border: 2px solid rgba(45, 45, 45, 0.3);
        border-radius: 12px;
        margin: 32px;
        transition: .5s;

        &:hover {
            background-color: rgba(255, 240, 219, 0.7);
            border: 2px solid rgba(45, 45, 45, 0.5);
            cursor: pointer;
            transition: .5s;
        }

        h2 {
            padding: 16px;
            margin: 0;
            @media only screen and (max-width: 800px) {
                font-size: 36px;
            }
            @media only screen and (max-width: 600px) {
                font-size: 24px;
            }
        }
    }

    canvas {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 0;
    }
</style>
