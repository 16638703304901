<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">Tell me of your history,<br /> so I may record it for this present</p>
            <p class="two">There was a boy who cut milk like eggs</p>
            <p class="two">He saw not the distant shore and what lurked beneath</p>
            <p class="two">There is a girl who weaves hair like grass</p>
            <p class="two">Not caring the mites and morays that languish beyond her screams</p>
            <p class="two">Here is a child, </p>
            <p class="two">What children do pushes beyond the boundaries of </p>
            <p class="two">Reason</p>
            <p class="two">a creation and mimicry</p>
            <p class="two">to keep us alive</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList')">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter8137652",
        emits: ['showList']
    }
</script>

<style scoped lang="scss">
    .one {
        font-family: anonymous-pro,monospace;
        text-transform: uppercase;
        text-align: left;
        width: 40%;
    }

    .two {
        text-align: right;
        line-height: .55;
    }
</style>