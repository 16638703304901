<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">C'mon, hop in a transporter! Just for a few hours, I want to hang out :(</p>
            <p class="two"><span> </span>You know I can't do that. The time fields in my era aren't stable, sending anything across beyond these messages could risk everything.</p>
            <p class="one">But I could use a friend</p>
            <p class="two"><span> </span>We are friends. </p>
            <p class="one">That doesn't make time-travel any less lonely</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList'); $emit('destroy415')">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter4152969",
        emits: ['showList', 'destroy415']
    }
</script>

<style scoped lang="scss">
    p {
        margin: 10px 0;
    }
    @font-face {
        font-family: marydale,sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .one {
        text-align: left;
        max-width: 50%;
        font-family: marydale, sans-serif;
        font-size: 26px;
        line-height: .85;
    }
    .two {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: right;
        font-family: ocr-a-std, monospace;
        font-size: 22px;
    }
</style>