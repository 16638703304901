<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one c">Are you here to just observe? To read?</p>
            <p class="one c slowGlitch" title="Surely you can imagine yourself doing something more?">Surely you can imagine yourself doing something more?</p>
            <p class="one">Here’s an idea for you: </p>
            <p id="destroy" class="two btn" @click="$emit('letDestroy'); chosen = true"><span class="orange">[</span> Destroy the monitor <span class="orange">]</span></p>

            <div v-if="sec10">
                <p class="one">No? Not good enough for you? Will this do then:</p>
                <p id="burn" class="two btn" @click="$emit('letBurn'); chosen = true"><span class="orange">[</span> Burn down the exhibit <span class="orange">]</span></p>
            </div>
            <div v-if="sec20">
                <p class="one">Oh, maybe we were wrong then. Perhaps this has some appeal:</p>
                <p id="alert" class="two btn"  @click="$emit('letAlert'); chosen = true"><span class="orange">[</span> Alert the guards to the exhibit hack <span
                    class="orange">]</span></p>
            </div>
            <div v-if="sec30">
                <br />
                <p class="one slowGlitch">None of these are fitting? Well, I don’t have the time (actually I do. unfortunately, you
                    don’t) to wait for you to choose. Obviously that also means you don’t have time for me to answer
                    your questions. Just know we will be reaching out again. </p>
                <br />
                <p class="one glitchSkew" data-text="Im quite glad we got through to you. Your TimeZone is always so hard to reach. Now that
                    this connection is established, we have quite the set of possibilities before us.">Im quite glad we got through to you. Your TimeZone is always so hard to reach. Now that
                    this connection is established, we have quite the set of possibilities before us.</p>
                <br />
                <p class="one orange">Do stay alive until next time.</p>
            </div>
        </div>
        <audio id="HelloWav">
            <source src="https://awikhigan.irlh.org/gen/wl/?id=xXaoCaR8W0gZ3pwwEs2JRszIDdD3llNU">
        </audio>
    </div>
</template>

<script>
    export default {
        name: "Letter234567",
        emits: ['letBurn', 'letAlert', 'letDestroy'],
        data() {
            return {
                sec10: false,
                sec20: false,
                sec30: false,
                chosen: false
            }
        },
        mounted() {
            let destroyP = document.getElementById('destroy');

            setTimeout(() => {
                if (!this.chosen) {
                    this.sec10 = true
                    const audio2 = document.getElementById('HelloWav')
                    audio2.play()
                    destroyP.classList.add('gray')
                    destroyP.classList.remove('btn')
                }

            }, 5000);
            setTimeout(() => {
                if (!this.chosen) {
                    this.sec20 = true
                    const audio2 = document.getElementById('HelloWav')
                    audio2.play()
                    let burnP = document.getElementById('burn')
                    burnP.classList.add('gray')
                    burnP.classList.remove('btn')
                }


            }, 12000);
            setTimeout(() => {
                if (!this.chosen) {
                    this.sec30 = true;
                    const audio2 = document.getElementById('HelloWav')
                    audio2.play()
                    let alertP = document.getElementById('alert')
                    alertP.classList.add('gray')
                    alertP.classList.remove('btn')
                }
            }, 19000);

        }
    }
</script>

<style scoped lang="scss">
    p {
        font-family: monospace;
    }
    .c {
        margin: 0;
    }
    .one {
        text-align: left;
    }

    .two {
        text-align: right;
    }

    .btn:hover {
        color: #b57300;
        cursor: pointer;
    }

    .orange {
        color: orange;
        font-weight: bold;
    }

    .gray {
        text-align: right;
        color: gray;
        text-decoration: line-through;
    }

    .slowGlitch {
        animation: glitch 5s linear infinite;
    }

    @keyframes glitch{
        2%,64%{
            transform: translate(2px,0) skew(0deg);
        }
        4%,60%{
            transform: translate(-2px,0) skew(0deg);
        }
        62%{
            transform: translate(0,0) skew(5deg);
        }
    }

    .slowGlitch:before,
    .slowGlitch:after{
        content: attr(title);
        position: absolute;
        left: 0;
    }

    .slowGlitch:before{
        animation: glitchTop 3s linear infinite;
        clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    }

    @keyframes glitchTop{
        2%,64%{
            transform: translate(2px,-2px);
        }
        4%,60%{
            transform: translate(-2px,2px);
        }
        62%{
            transform: translate(13px,-1px) skew(-13deg);
        }
    }

    .slowGlitch:after{
        animation: glitchBottom 2.5s linear infinite;
        clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
        -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    }

    @keyframes glitchBottom{
        2%,64%{
            transform: translate(2px,0);
        }
        4%,60%{
            transform: translate(-2px,0);
        }
        62%{
            transform: translate(-22px,5px) skew(21deg);
        }
    }

    @mixin glitchCopy {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .glitchSkew {
        position: relative;
        //color: white;
        //font-size: 4em;
        /* Animation provies a slight random skew. Check bottom of doc
        for more information on how to random skew. */
        animation: glitch-skew 5s infinite linear alternate-reverse;

        // Creates a copy before our text.
        &::before{
            // Duplicates our text with the mixin.
            @include glitchCopy;
            left: 2px;
            text-shadow: -2px 0 rgba(169, 71, 253, 0.18);
            /* Creates an initial clip for our glitch. This works in
            a typical top,right,bottom,left fashion and creates a mask
            to only show a certain part of the glitch at a time. */
            clip: rect(44px, 450px, 56px, 0);
            /* Runs our glitch-anim defined below to run in a 5s loop, infinitely,
            with an alternating animation to keep things fresh. */
            animation: glitch-anim 5s infinite linear alternate-reverse;
        }

        &::after {
            @include glitchCopy;
            left: -2px;
            text-shadow: -2px 0 rgba(0, 255, 249, 0.24), 2px 2px rgba(169, 71, 253, 0.32);
            animation: glitch-anim2 1s infinite linear alternate-reverse;
        }
    }

    @keyframes glitch-anim {
        $steps: 20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
                transform: skew((random(100) / 100) + deg);
            }
        }
    }

    @keyframes glitch-anim2 {
        $steps: 20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
                transform: skew((random(100) / 100) + deg);
            }
        }
    }

    @keyframes glitch-skew {
        $steps: 10;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                transform: skew((random(10) - 5) + deg);
            }
        }
    }
</style>