<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">Here is a woman. She is broken and charred. Flesh rotting beneath flame.</p>
            <p class="one">Here is a man. Clear-eyed and horrendous. Destructive without care or realization.</p>
            <p class="one">Here is a child. Torn down with loss. Mercy was never imagined.</p>
            <br />
            <p class="two"><span> </span>These are but glimpses of what I requested.</p>
            <br />
            <p class="one">No, not even that. I have shown you only histories.</p>
            <br />
            <p class="two"><span> </span>These are the records you would have me keep? <br />This is how you wish your time to remembered?</p>
            <br />
            <p class="one">Yes.</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList'); $emit('show813');">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter3552610",
        emits: ['showList', 'show813']
    }
</script>

<style scoped lang="scss">
    @font-face {
        font-family: kinesis-pro-3,serif;
        font-weight: 400;
        font-style: normal;
    }

    .one {
        text-align: left;
        font-family: kinesis-pro-3,serif;
        line-height: .35
    }
    .two {
        font-family: anonymous-pro,monospace;
        text-transform: uppercase;
        text-align: right;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
    }
</style>