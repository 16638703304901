<template>
    <div id="letter">
        <p class="one">Yeah! Go for it!</p>
        <p class="one">We're definitely going to be good friends. Rebels and all that. Stay alive! I'll be contacting you again soon</p>
    </div>
</template>

<script>
    export default {
        name: "LetterDestroy"
    }
</script>

<style scoped lang="scss">

    #letter {
        justify-content: normal;
        z-index: -1;
    }
    p {
        font-family: monospace;
    }
</style>