<template>
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
</template>

<style lang="scss">
    @import url("https://use.typekit.net/tcx3smp.css");

    @font-face {
        font-family: ocr-a-std, monospace;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: anonymous-pro, monospace;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: felt-tip-woman, sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: gloria-hallelujah, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    html {
        scroll-behavior: smooth;
        @media only screen and (max-width: 800px) {
            overflow: scroll;
        }
    }

    body {
        margin: 0;
        overflow: hidden;
        @media only screen and (max-width: 800px) {
            overflow: scroll;
        }
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @media only screen and (max-width: 800px) {
            overflow: scroll;
        }
    }

    h1, h2, h3, p {
        font-family: "Calisto MT", 'Times New Roman', serif;
    }

    h1 {
        font-size: 96px;
        font-weight: normal;
    }

    h2 {
        font-size: 48px;
        font-weight: normal;
    }

    h3 {
        font-size: 36px;
        font-weight: normal;
    }

    p {
        font-size: 24px;
    }

    a {
        text-decoration: none;
    }

    #myVideo {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: 1;
    }

    .pageContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 60%;
        margin: 0 auto;
    }

    #letter {
        padding: 20px 40px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 60vh;
        min-height: 40vh;
    }

    .number {
        background-color: #1f1f1f;
        font-family: monospace;
        font-weight: bold;
        color: #f1f1f1;
        padding: 6px;
        display: flex;
        margin: 0;
        width: 100px;
        justify-content: center;
        text-align: center;
    }

    .closeContainer {

    }

    .closeText {
        text-align: center;
    }

    .closeButton {
        color: #292929;
        background-color: #e9e9e9;
        padding: 8px;
        font-size: 20px;
        font-family: monospace;
        cursor: pointer;
        border: 2px solid black;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-enter-active, .modal-leave-active {
        opacity: 0;
        transition: opacity .75s;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    #app {
        background-color: black;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1.25s ease;
    }


    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        transition: .75s ease;
    }
</style>
