import {createRouter, createWebHistory} from 'vue-router'

import Intro from '../pages/Intro.vue'
import CuratorLetter from "@/pages/CuratorLetter";
import LetterList from "@/pages/LetterList";
import Room from "@/pages/Room";
import Warning from "@/pages/Warning";

const routes = [
    {
        path: '/',
        name: 'Warning',
        component: Warning
    },
    {
        path: '/intro',
        name: 'Intro',
        component: Intro
    },
    {
        path: '/enter',
        name: 'Room',
        component: Room
    },
    {
        path: '/curator',
        name: 'CuratorLetter',
        component: CuratorLetter
    },
    {
        path: '/letters',
        name: 'LetterList',
        component: LetterList
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
