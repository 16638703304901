<template>
    <div class="container">
        <p></p>
        <div id="curatorLetter">
            <div class="pageContainer">
                <div class="letterContainer">
                    <div class="letter">
                        <h2>A Very Temporal Letter from the Curator</h2>
                        <p>Dearest Visitor to the Living Museum, </p>
                        <p>The exhibit you are about to view is a collection of intercepted transmissions, often captured
                            mid-exchange, from
                            time-traveling rebels and their confidants. While researchers at the Time Council have not yet been
                            able to
                            extract the dates of transmission, nor the identities of the senders and recipients, what is clear
                            is that the
                            boundaries of space-time do not restrict communications between these rebels. It is only through the
                            sheer
                            genius of the Time Council’s technicians that each transmission has been seized and stabilized for
                            your viewing
                            pleasure.</p>
                        <p>A warning, Visitor: the content of this exhibit includes many of the radical, dangerous, and false
                            ideas that
                            drove these rebels to their destruction. Though their venomous thought may be enticing, you are
                            required to
                            resist. Steel yourself, so you do not fall prey to these siren calls. Read them instead as
                            instructive examples
                            of madness and insolence, a reminder of the consequences of letting our mind be led astray. And
                            remember, the
                            Museum remains watchful.</p>
                        <p>We thank you for your continued patronage of our esteemed collections.</p>
                    </div>
                    <div class="signature">
                        <h3>— Assistant Curator August</h3>
                        <p class="title">The Time Council</p>
                    </div>
                </div>
                <router-link :to="{ name: 'LetterList' }">
                    <div class="continue">
                        <h2>Continue</h2>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="aboutButton" @click="showModal = true">
            <p>?</p>
        </div>
        <transition name="modal">
            <About v-if="showModal" v-on:closeModal="showModal = false"/>
        </transition>
        <audio id="bgWaves" autoplay loop>
            <source src="https://awikhigan.irlh.org/gen/wl/?id=sbYv0PDfeKSpJUh9zVixou9uHdDDsmV6">
        </audio>
        <video playsinline autoplay muted loop id="myVideo">
            <source src="https://awikhigan.irlh.org/gen/wl/?id=kn41VtGldCQcctovZYfESWpCFWHxL2iA" type="video/mp4">
        </video>
    </div>
</template>

<script>
    import About from "@/components/About";

    export default {
        name: "CuratorLetter",
        components: {About},
        data() {
            return {
                showModal: false
            }
        },
        mounted() {
            // setTimeout(this.playBGWaves, 1000)
        },
        methods: {
            playBGWaves() {
                const audio = document.getElementById('bgWaves')
                audio.play()
            }
        }
    }
</script>

<style scoped lang="scss">

    h1, h2, h3, p {
        font-family: "Calisto MT", 'Times New Roman', serif;
        text-align: left;
        color: #131313;
    }
    h2 {
        @media only screen and (max-width: 700px) {
            font-size: 48px;
        }
    }

    #curatorLetter {
        position: fixed;
        min-height: 100vh;
        width: 100vw;
        z-index: 4;
    }

    .pageContainer {
        // position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100vh;
        max-width: 1400px;
        width: 60%;
        margin: 0 auto;
        @media only screen and (max-width: 1400px) {
            width: 80%;
        }
        @media only screen and (max-width: 800px) {
            height: 100%;
        }
    }

    .letterContainer {
        background-image: linear-gradient(rgba(222, 218, 216, 0.7), rgba(206, 202, 199, 0.5));
        padding: 0 8px;
    }

    .signature {
        h3, p {
            text-align: right;
        }

        h3 {
            margin-bottom: 8px;
        }
    }

    .title {
        margin-top: 0;
    }

    .continue {
        background-color: #F8F8F8;
        border: 1px solid #2E2E2E;
        filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.25)) drop-shadow(-5px -3px 5px rgba(0, 0, 0, 0.2));
        width: 250px;
        margin: 0 auto;

        h2 {
            font-size: 36px;
            text-align: center;
            margin: 12px 0;
        }
    }

    .aboutButton {
        position: fixed;
        top: 20px;
        right: 40px;
        z-index: 5;
        background-color: #ffedc7;
        padding: 12px 22px;
        border-radius: 40px;
        border: 1px solid #343434;
        cursor: pointer;

        p {
            margin: 0;
            font-size: 25px;
        }
    }
</style>