<template>
    <div class="warningContainer">
        <div id="warning">
            <h1>Warning</h1>
            <p>This website contains flashing glitch effects which may be uncomfortable or dangerous for certain
                viewers.</p>
            <p>It is recommended to view on a desktop or laptop computer using Google Chrome.<br/> This site is not
                fully functional on mobile devices or Safari</p>
            <router-link :to="{ name: 'Room'}">
                <p class="button">Acknowledge</p>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Warning"
    }
</script>

<style scoped lang="scss">
    .warningContainer {
        width: 100vw;
        min-height: 100vh;
        background-color: #f6f6f6;
        overflow: scroll;
    }

    #warning {
        max-width: 1140px;
        width: 85%;
        margin: 0 auto;
        text-align: center;
        color: #151515;
        @media only screen and (max-width: 800px) {
            width: 90%;
        }
    }

    h1 {
        padding-top: 80px;
        margin: 0;
        font-size: 36px;
        @media only screen and (max-width: 800px) {
            padding-top: 20px;
        }
    }

    .button {
        border: 1px solid black;
        cursor: pointer;
        color: black;
        background-color: #dddddd;
        width: 200px;
        height: 50px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
</style>