<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">Sector 407 contains several anti-matter bombs— that might be—</p>
            <p class="two"><span> </span>Comrade! Resend that comm, it cut out!</p>
            <p class="one">Sector 40— anti— bombs— </p>
            <p class="two"><span> </span>You’re breaking up, comrade! Where and when can we find these bombs? What are they for?</p>
            <p class="one">—peace.</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList'); $emit('destroy541')">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter5413285",
        emits: ['showList', 'destroy541']
    }
</script>

<style scoped lang="scss">
    @font-face {
        font-family: filicudi-color-pride,sans-serif;
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: mono45-headline,monospace;
        font-weight: 700;
        font-style: normal;
    }
    .one {
        font-family: filicudi-color-pride,sans-serif;
        text-align: left;
        width: 40%;
    }
    .two {
        font-family: mono45-headline,monospace;
        text-align: right;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
    }
</style>