<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="closeContainer">
                        <span class="closeModal" @click="$emit('closeModal')">X</span>
                    </div>
                    <h3>About this project</h3>
                    <p><span class="title">Anti-Temporal Letters</span> is a digital project created by <a
                        href="https://awwsmith.com" target="_blank">Andrew W. Smith</a>. The project emerges from a line in the opening sequence of Janelle Monae's
                        <a href="https://www.youtube.com/watch?v=tEddixS-UoU" target="_blank">music video for "Q.U.E.E.N."</a>. The line refers to a "Time Council and Living Museum", a fictional organization dedicated to the capture and exhibition of time-traveling rebels. <span class="title">Anti-Temporal Letters</span> riffs on this concept, imagining what those rebels may have communicated to each other across space and time.</p>
                    <p>This is a creative and fictional work with no formal relation to Monae's body of work, nor does it represent actual people or communications.</p>
                    <p>It is recommended to view this project on a desktop or laptop computer using Google Chrome. Safari, Internet Explorer, mobile devices, and other internet browsers may produce a lesser experience.</p>
                    <p><span class="title">Anti-Temporal Letters</span> was originally published in the Fall 2021 Issue of <a href="https://thenewriver.us/anti-temporal-letters/" target="_blank">The New River</a>.</p>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "About",
        emits: ['closeModal']
    }
</script>

<style scoped lang="scss">
    a {
        color: #a064f3;
    }
    h3 {
        margin-top: 0;
    }
    .title {
        font-style: italic;
    }
    .closeContainer {
        display: flex;
        justify-content: flex-end;
    }
    .closeModal {
        background-color: #eaeaea;
        color: #3b3b3b;
        font-size: 24px;
        height: 40px;
        width: 40px;
        cursor: pointer;
        border: 2px solid black;
        transition: .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: monospace;
        &:hover {
            transition: .5s;
        }
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 800px;
        max-height: 80vh;
        overflow-y: auto;
        margin: 0 auto;
        padding: 40px;
        background-color: #fff;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.35);
        transition: all 0.3s ease;
    }
</style>