<template>
    <div id="letter">
        <p class="one">Wait actually? Hold on!</p>
        <p class="one">I mean, just go for it actually, why not. </p>
        <p class="one">I'll be reaching out again if you make it out alive!</p>
    </div>
</template>

<script>
    export default {
        name: "LetterBurn"
    }
</script>

<style scoped>
    #letter {
        justify-content: normal;
    }
    p {
        font-family: monospace;
    }
</style>