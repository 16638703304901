<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">It’s odd that they turn to us now</p>
            <p class="two"><span> </span>What of it?</p>
            <p class="one">They never listened to us before</p>
            <p class="two"><span> </span>Still don't.</p>
            <p class="one">True, but now we are in the driver’s seat. Literally</p>
            <p class="two"><span> </span>Superficially. Control overrides are still in place.</p>
            <p class="one">Let a kid dream</p>
            <p class="two"><span> </span>Hahaha, when’s the last time any of us actually dreamed?</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList'); $emit('show315');">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter8217159",
        emits: ['showList', 'show315']
    }
</script>

<style scoped lang="scss">
    p {
        margin: 10px 0;
    }

    .one {
        text-align: left;
        font-family: felt-tip-woman, sans-serif;
        font-weight: 700;
        font-size: 30px;
        width: 60%;
    }
    .two {
        text-align: right;
        font-family: gloria-hallelujah, sans-serif;
        font-size: 22px;
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
</style>