<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">The adults dream, you know</p>
            <p class="two"><span> </span>Of a past projected onto the future. A past only they remember.</p>
            <p class="one">Why won’t you just have a little hope?</p>
            <p class="two"><span> </span>Because why should I believe they are going to treat us, or the planet, any differently if we succeed in saving it and this all ends? We’ll still be just cogs in their machine.</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList')">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter3159756",
        emits: ['showList']
    }
</script>

<style scoped lang="scss">
    .one {
        text-align: left;
        font-family: felt-tip-woman, sans-serif;
        font-weight: 700;
        font-size: 30px;
    }
    .two {
        text-align: right;
        font-family: gloria-hallelujah, sans-serif;
        font-size: 22px;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
    }
</style>