<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">I am tired of holding onto these memories alone.</p>
            <p class="two">Me too.</p>
            <p class="one">Haunt the World with me when this is over?</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList')">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter2057692",
        emits: ['showList']
    }
</script>

<style scoped lang="scss">
    @font-face {
        font-family: recherche, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: krul, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    .one {
        font-family: 'recherche', sans-serif;
        text-align: left;
        font-size: 32px;
        line-height: 1;
    }
    .two {
        font-family: 'krul', sans-serif;
        text-align: right;
        line-height: 1;
    }
</style>