<template>
    <div id="letter">
        <div class="letterContainer">
            <p class="one">I think I have your children. They safe.</p>
            <p class="two">What you mean? They make it?</p>
            <p class="one">Stayed together too</p>
            <p class="two">Lord lookin’ down</p>
            <p class="one">Surely is</p>
            <p class="two">Where you?</p>
            <p class="one">Bayou country.</p>
            <p class="two">Took 'em far then</p>
            <p class="one">We all been taken far. Too far.</p>
            <p class="two">Truth</p>
            <p class="one">I'll look after 'em. Best I can.</p>
        </div>
        <div class="closeContainer">
            <p class="closeText">
                <span class="closeButton" @click="$emit('showList')">Close</span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Letter6157835",
        emits: ['showList']
    }
</script>

<style scoped lang="scss">
    p {
        margin: 12px 0;
    }
    @font-face {
        font-family: gryphius-mvb, serif;
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: albertan-pro, sans-serif;
        font-weight: 500;
        font-style: italic;
    }

    .one {
        text-align: left;
        font-family: gryphius-mvb, serif;
        font-style: italic;
        font-size: 28px;
    }

    .two {
        text-align: right;
        font-family: albertan-pro, sans-serif;
        font-style: italic;
        font-size: 26px;
    }
</style>